<template>
  <div class="card mt-2">
    <div class="card-header bg-info">
      <h5 class="card-title font-weight-semibold">
        Tambah Edukasi Terintegrasi Harian
      </h5>
    </div>
    <validation-observer ref="VForm">
      <b-form @submit.prevent="doSubmit">
        <div class="card-body">
          <div class="row gx-2">
            <div class="col-md-4">
                <label>Tanggal Edukasi <span class="text-danger mr5">*</span></label>
                <div class="input-group mb-3">
                  <datepicker input-class="form-control transparent h-38"
                    placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar"
                    v-model="isParent.rowET.auieth_tanggal_edukasi">
                  </datepicker>

                  <vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38"
                    v-model="isParent.rowET.auieth_waktu_edukasi">
                  </vue-timepicker>
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                  </div>
                </div>
                <VValidate :name="'Tanggal'" v-model="isParent.rowET.auieth_tanggal_edukasi" :rules="{required : 1}" class="d-block" />
                <VValidate :name="'Jam'" v-model="isParent.rowET.auieth_waktu_edukasi" :rules="{required: 1}" />
            </div>
          </div>

          
          <div class="row mt-3">
            <div class="col-md-6">
              <label>Uraian Penjelasan <span class="text-danger mr5">*</span></label>
              <div class="input-group">
                <b-form-textarea rows="6" v-model="isParent.rowET.auieth_uraian_penjelasan"></b-form-textarea>
              </div>
              <VValidate name="Uraian Penjelasan" v-model="isParent.rowET.auieth_uraian_penjelasan" :rules="{required: 1, max:1500}" />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <h6 class="font-weight-semibold text-info">Penerima Penjelasan</h6>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label>Hubungan dengan pasien <span class="text-danger">*</span></label>
                <b-form-input v-model="isParent.rowET.auieth_hubungan_pasien" />

                <VValidate name="Hubungan dengan pasien" v-model="isParent.rowET.auieth_hubungan_pasien" :rules="{required : 1, min: 3, max: 128}" />
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label>Nama <span class="text-danger">*</span></label>
                <b-form-input v-model="isParent.rowET.auieth_hubungan_pasien_nama" />

                <VValidate name="Nama" v-model="isParent.rowET.auieth_hubungan_pasien_nama" :rules="{required : 1, min: 3, max: 128}" />
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h6 class="font-weight-semibold text-info">Tanda Tangan</h6>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Edukator <span class="text-danger">*</span></label>                

                <div class="signing-element mt-1" data-target-id="ttdEdukator">
                  <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdEdukator" @onend="onEnd('ttdEdukator')" />
                  <VValidate name="Tanda Tangan Edukator" v-model="isParent.rowET.auieth_edukator" :rules="{required: 1}" />
                  <div class="mt-2">
                    <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" type="button" @click="undoTTD('ttdEdukator','auieth_edukator')">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button type="button" @click="output('ttdEdukator','auieth_edukator')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Penerima Penjelasan <span class="text-danger">*</span></label>                
                <div class="signing-element mt-1" data-target-id="ttdSasaran">
                  <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdSasaran" @onend="onEnd('ttdSasaran')" />
                  <VValidate name="Tanda Penerima Penjelasan" v-model="isParent.rowET.auieth_sasaran" :rules="{required: 1}" />
                  <div class="mt-2">
                    <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" type="button" @click="undoTTD('ttdSasaran','auieth_sasaran')">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button type="button" @click="output('ttdSasaran','auieth_sasaran')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="button" class="btn" @click="$router.back()">Kembali</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default{
  extends: GlobalVue,
  components: {Datepicker,VueTimepicker}, 
  computed: {
    isParent(){
      return this.$parent.$parent
    },
  
    isQuestionMateri(){
      return (this.isParent.rowET.auieth_materi_edukasi||[]).find(dtMateri => dtMateri.value === "ADD_QUESTION")
    }
    // modulePage(){
    //   return 'UGDKajianStriptokinase' 
    // }
  },
  methods: {
    toValidate(val){
      return {...val}
    },   
    setSignature(signature, refs) {
      setTimeout(() => {
        if (signature) this.$refs[refs].fromDataURL(signature)
      }, 1000)
    },
    undoTTD(refs, field) {
      this.$refs[refs].clearSignature()
      this.$set(this.isParent.rowET, field, null)
    },
    output(refs, field) {
      let {
        isEmpty,
        data
      } = this.$refs[refs].saveSignature()
      if (!isEmpty) {
        this.$set(this.isParent.rowET, field, data)
      }
    },
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.isParent.rowET
              data.type = 'submit-data-et-harian'
              
              this.isParent.loadingOverlay = true
              Gen.apiRest(
              "/do/" + 'UGDET', {
                  data: data
              },
              "POST"
              ).then(res => {
                this.isParent.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'UGDET' }).catch(()=>{})   
                })
              }).catch(err => {
                this.isParent.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    }
  },
  mounted() {
    setTimeout(() => {
      if(this.isParent.rowET.auieth_edukator) this.setSignature(this.isParent.rowET.auieth_edukator, 'ttdEdukator')
      if(this.isParent.rowET.auieth_sasaran) this.setSignature(this.isParent.rowET.auieth_sasaran, 'ttdSasaran')
    }, 1500)
  },
}

</script>
