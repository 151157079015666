<template>
  <div class="card mt-2">
    <div class="card-header bg-info">
      <h5 class="card-title font-weight-semibold">
        {{isHaveET ? 'Edit' : 'Tambah'}} Edukasi Terintegrasi
      </h5>
    </div>
    <validation-observer ref="VForm">
      <b-form @submit.prevent="doSubmit">
        <div class="card-body">
          <div class="row gx-2">
            <div class="col-md-4">
                <label>Tanggal Edukasi <span class="text-danger mr5">*</span></label>
                <div class="input-group mb-3">
                  <datepicker input-class="form-control transparent h-38"
                    placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar"
                    v-model="isParent.rowET.auet_tanggal_edukasi">
                  </datepicker>

                  <vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38"
                    v-model="isParent.rowET.auet_waktu_edukasi">
                  </vue-timepicker>
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                  </div>
                </div>
                <VValidate :name="'Tanggal'" v-model="isParent.rowET.auet_tanggal_edukasi" :rules="{required : 1}" class="d-block" />
                <VValidate :name="'Jam'" v-model="isParent.rowET.auet_waktu_edukasi" :rules="{required: 1}" />
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Durasi Waktu<strong class="text-danger">*</strong></label>
                <div class="input-group">
                  <b-form-input type="text" v-model="isParent.rowET.auet_durasi" />
                  <div class="input-group-append"><span class="input-group-text">menit</span></div>
                </div>

                <VValidate name="Durasi" v-model="isParent.rowET.auet_durasi" :rules="isParent.mrValidation.auet_durasi" />
              </div>
            </div>
          </div>

          <div class="row g-3">
            <div class="col-md-4">
              <b-form-group>
                <label>
                  Materi Edukasi <span class="text-danger">*</span>
                </label>

                <b-form-checkbox-group
                  v-model="isParent.rowET.auet_materi_edukasi"
                  name="`materi`"
                >
                  <b-form-checkbox 
                    v-for="(v, k) in isParent.rowET.mr_materi_edukasi" :key="k" 
                    :value="v"
                    :id="`materi${k}`"
                    class="mb-2"
                  >
                    {{ v.label }}
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <VValidate 
                  name="Materi Edukasi"
                  :value="(isParent.rowET.auet_materi_edukasi||[]).length > 0 ? true : null"
                  :rules="isParent.mrValidation.auet_materi_edukasi"
                />
              </b-form-group>

              <b-form-group v-if="isQuestionMateri">
                <label>Pertanyaan</label>
                <b-form-textarea rows="6" v-model="isParent.rowET.auet_question"></b-form-textarea>

                <VValidate
                  name="Pertanyaan"
                  v-model="isParent.rowET.auet_question"
                  rules="required"
                />
              </b-form-group>
            </div>
            

            <div class="col-md-8">
              <b-form-group class="mb-2">
                <label>
                  Tingkat Pemahaman Awal <span class="text-danger">*</span>
                </label>
                <b-form-radio-group v-model="isParent.rowET.auet_tingkat_pemahaman_awal" :options="isParent.rowET.mr_pemahaman_awal" text-field="label" value-field="value"></b-form-radio-group>
                <VValidate name="Tingkat Pemahaman Awal" v-model="isParent.rowET.auet_tingkat_pemahaman_awal" :rules="isParent.mrValidation.auet_tingkat_pemahaman_awal" />
              </b-form-group>

              <b-form-group class="mb-2">
                <label>
                  Metode Edukasi <span class="text-danger">*</span>
                </label>

                <b-form-checkbox-group
                  v-model="isParent.rowET.auet_metode_edukasi"
                  name="`metode_edukasi`"
                >
                  <b-form-checkbox 
                    v-for="(v, k) in isParent.rowET.mr_metode_edukasi" :key="k" 
                    :value="v"
                    :id="`metode_edukasi${k}`"
                    class="mb-2"
                  >
                    {{ v.label }}
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <VValidate 
                  name="Metode Edukasi"
                  :value="(isParent.rowET.auet_metode_edukasi||[]).length > 0 ? true : null"
                  :rules="isParent.mrValidation.auet_metode_edukasi"
                />
              </b-form-group>

              <b-form-group>
                <label>
                  Sarana Edukasi <span class="text-danger">*</span>
                </label>

                <b-form-checkbox-group
                  v-model="isParent.rowET.auet_sarana_edukasi"
                  name="`sarana`"
                >
                  <b-form-checkbox 
                    v-for="(v, k) in isParent.rowET.mr_sarana_edukasi" :key="k" 
                    :value="v"
                    :id="`sarana${k}`"
                    class="mb-2"
                  >
                    {{ v.label }}
                  </b-form-checkbox>
                </b-form-checkbox-group>

                <VValidate 
                  name="Sarana Edukasi"
                  :value="(isParent.rowET.auet_sarana_edukasi||[]).length > 0 ? true : null"
                  :rules="isParent.mrValidation.auet_sarana_edukasi"
                />
              </b-form-group>
            </div>
          </div>


          
          <div class="row">
            <div class="col-md-12">
              <hr />

              <label>
                Evaluasi <span class="text-danger">*</span>
              </label>
              <b-form-group>
                <b-form-radio-group v-model="isParent.rowET.auet_evaluasi" :options="isParent.rowET.mr_evaluasi_edukasi" text-field="label" value-field="value"></b-form-radio-group>
                <VValidate name="Evaluasi" v-model="isParent.rowET.auet_evaluasi" :rules="isParent.mrValidation.auet_evaluasi" />
              </b-form-group>              
            </div>

            <div class="col-md-5" v-if="isParent.rowET.auet_evaluasi == 'RE_EDUKASI'">
              <label>Tanggal Re-edukasi <span class="text-danger mr5">*</span></label>
              <div class="input-group mb-3">
                <datepicker input-class="form-control transparent h-38"
                  placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar"
                  v-model="isParent.rowET.auet_tanggal_evaluasi">
                </datepicker>

                <vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38"
                  v-model="isParent.rowET.auet_waktu_evaluasi">
                </vue-timepicker>
                <div class="input-group-append calendar-group">
                  <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                </div>
              </div>
              <VValidate :name="'Tanggal Re Edukasi'" v-model="isParent.rowET.auet_tanggal_evaluasi" :rules="{required : 1}" class="d-block" />
              <VValidate :name="'Jam Re Edukasi'" v-model="isParent.rowET.auet_waktu_evaluasi" :rules="{required: 1}" />
            </div>

            <div class="col-md-12 mt-2">
              <h6 class="font-weight-semibold text-info">Sasaran</h6>
              <label>
                Edukasi diterima pasien? <span class="text-danger">*</span>
              </label>
              <b-form-group>
                <b-form-radio-group v-model="isParent.rowET.auet_edukasi_diterima_pasien" :options="Config.mr.yesNoOptV2" text-field="text" value-field="value"></b-form-radio-group>
                <VValidate name="Sasaran" v-model="isParent.rowET.auet_edukasi_diterima_pasien" :rules="isParent.mrValidation.sasaran" />
              </b-form-group>
            </div>

          </div>

          <div class="row mt-2">
            <div class="col-md-12">
              <h6 class="font-weight-semibold text-info">Penerima Penjelasan</h6>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label>Hubungan dengan pasien <span class="text-danger">*</span></label>
                <b-form-input v-model="isParent.rowET.auet_hubungan_pasien" />

                <VValidate name="Hubungan dengan pasien" v-model="isParent.rowET.auet_hubungan_pasien" :rules="isParent.mrValidation.auet_hubungan_pasien" />
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label>Nama <span class="text-danger">*</span></label>
                <b-form-input v-model="isParent.rowET.auet_hubungan_pasien_nama" />

                <VValidate name="Nama" v-model="isParent.rowET.auet_hubungan_pasien_nama" :rules="isParent.mrValidation.auet_hubungan_pasien_nama" />
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h6 class="font-weight-semibold text-info">Tanda Tangan</h6>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Edukator <span class="text-danger">*</span></label>                

                <div class="signing-element mt-1" data-target-id="ttdEdukator">
                  <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdEdukator" @onend="onEnd('ttdEdukator')" />
                  <VValidate name="Tanda Tangan Edukator" v-model="isParent.rowET.auet_edukator" :rules="isParent.mrValidation.auet_edukator" />
                  <div class="mt-2">
                    <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" type="button" @click="undoTTD('ttdEdukator','auet_edukator')">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button type="button" @click="output('ttdEdukator','auet_edukator')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Sasaran <span class="text-danger">*</span></label>                
                <div class="signing-element mt-1" data-target-id="ttdSasaran">
                  <VueSignaturePad class="sign-container" width="100%" height="160px" ref="ttdSasaran" @onend="onEnd('ttdSasaran')" />
                  <VValidate name="Tanda Tangan Sasaran" v-model="isParent.rowET.auet_sasaran" :rules="isParent.mrValidation.auet_sasaran" />
                  <div class="mt-2">
                    <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" type="button" @click="undoTTD('ttdSasaran','auet_sasaran')">
                      <b><i class="icon-cross3"></i></b>
                      <span>Clear</span>
                    </button>
                    <button type="button" @click="output('ttdSasaran','auet_sasaran')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        
        <div class="card-footer">
          <div class="text-right">
            <button type="button" class="btn" @click="$router.back()">Kembali</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default{
  extends: GlobalVue,
  components: {Datepicker,VueTimepicker}, 
  computed: {
    isParent(){
      return this.$parent.$parent
    },
    isHaveET(){
      let val
      if(this.$route.query.typeForm == 'PERAWAT'){
        val = this.isParent.rowReg.aur_is_have_et_perawat == 'Y' ? true : false
      }else if(this.$route.query.typeForm == 'DOKTER'){
        val = this.isParent.rowReg.aur_is_have_et_dokter == 'Y' ? true : false
      }else if(this.$route.query.typeForm == 'BIDAN'){
        val = this.isParent.rowReg.aur_is_have_et_bidan == 'Y' ? true : false
      }else if(this.$route.query.typeForm == 'FARMASI'){
        val = this.isParent.rowReg.aur_is_have_et_farmasi == 'Y' ? true : false
      }else if(this.$route.query.typeForm == 'NYERI'){
        val = this.isParent.rowReg.aur_is_have_et_nyeri == 'Y' ? true : false
      }

      return val

    },
  
    isQuestionMateri(){
      return (this.isParent.rowET.auet_materi_edukasi||[]).find(dtMateri => dtMateri.value === "ADD_QUESTION")
    }
    // modulePage(){
    //   return 'UGDKajianStriptokinase' 
    // }
  },
  methods: {
    toValidate(val){
      return {...val}
    },   
    setSignature(signature, refs) {
      setTimeout(() => {
        if (signature) this.$refs[refs].fromDataURL(signature)
      }, 1000)
    },
    undoTTD(refs, field) {
      this.$refs[refs].clearSignature()
      this.$set(this.isParent.rowET, field, null)
    },
    output(refs, field) {
      let {
        isEmpty,
        data
      } = this.$refs[refs].saveSignature()
      if (!isEmpty) {
        this.$set(this.isParent.rowET, field, data)
      }
    },
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.isParent.rowET
              data.type = 'submit-data-et'
              
              this.isParent.loadingOverlay = true
              Gen.apiRest(
              "/do/" + 'UGDET', {
                  data: data
              },
              "POST"
              ).then(res => {
                this.isParent.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'UGDET' }).catch(()=>{})   
                })
              }).catch(err => {
                this.isParent.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    }
  },
  mounted() {
    setTimeout(() => {
      if(this.isParent.rowET.auet_edukator) this.setSignature(this.isParent.rowET.auet_edukator, 'ttdEdukator')
      if(this.isParent.rowET.auet_sasaran) this.setSignature(this.isParent.rowET.auet_sasaran, 'ttdSasaran')
    }, 2000)
  },
  watch: {
    'isQuestionMateri'(v){
      if(!v) this.$set(this.isParent.rowET, 'auet_question', null)
    }
  }
}

</script>
