<template>

  <div class="content-wrapper">
    <div v-if="isList" class="content pt-0">
      <bo-page-title />
      <div class="card">
        <div class="card-header bg-white">
            <div class="row align-items-center">
                <div class="col-md-5">
                    <div class="form-row align-items-center">
                        <div class="col-md-4">
                            <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                        </div>
                        <div class="col-md-8">
                            <div class="form-group mb-0">
                                    <div class="input-group">
                                        <date-range-picker
                                            ref="picker"
                                            :locale-data="datePickerConfig.locale"
                                            :autoApply="datePickerConfig.autoApply"
                                            v-model="dateRange"
                                            :opens="'right'"
                                            :ranges="datePickerConfig.ranges"
                                            @update="updateValues"
                                        >
                                            <template v-slot:input="picker">
                                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                            </template>
                                        </date-range-picker>
                                        <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-auto col-lg-7">
                    <div class="form-row justify-content-end">
                        <div class="col-md-5">
                            <div class="lbl_status_pasien bg_red">
                                <h3>
                                    <i class="icon-users2"></i>
                                    {{pasienBaru}} PASIEN BARU
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="lbl_status_pasien bg_green">
                                <h3>
                                    <i class="icon-user-check"></i>
                                    {{pasienLama}} PASIEN LAMA
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="card-header">
            <div class="row">
                <div class="col-lg-9">
                    <div class="row gx-1">
                        <div class="col-md">
                            <div class="row gx-1">
                                <div class="col-md-auto">
                                    <div class="form-group mb-0">
                                        <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                    </div>
                                </div>
                                                          
                                <div class="col-md-3">
                                    <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_pasien"  :options="Config.mr.statusPasien" label="text" :reduce="v=>v.value"></v-select>
                                </div>
                                
                                <div class="col-md-4">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder=" -- Pilih Rujukan -- " @input="doFill" v-model="filter.status_rujukan"  :options="Config.mr.statusRujukanUGD" label="text" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                        <input class="form-control"
                            v-model="filter.search"
                            @input="doSearch"
                            placeholder="Ketik Nama/No. RM Pasien"
                        />
                        <div class="form-control-feedback">
                            <i class="icon-search4 text-indigo"></i>
                        </div>
                            
                        <b-button
                            class="ml-1 d-inline-flex align-items-center"
                            variant="outline-success"
                            id="resetBtn"
                            @click="doResetData()"
                        >Reset</b-button>
                    </div>
                </div>
            </div>
        </div>     

        
        <div class="table-responsive">
            <table
                class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                <thead>
                    <tr>
                        <th>NO.</th>
                        <th>TRIASE</th>
                        <th>NO. KEDATANGAN</th>
                        <th>NO RM</th>
                        <th>NAMA/NO KTP</th>                               
                        <th>TANGGAL LAHIR</th>
                        <th>USIA</th>
                        <th>JENIS KELAMIN</th>
                        <th>TANGGAL DAFTAR</th>
                        <th>CARA BAYAR</th>
                        <th>STATUS</th>
                        <th>AKSI</th>
                    </tr>
                </thead>
                
                <tbody v-if="(dataList||[]).length">
                    <tr v-for="(v,k) in (dataList||[])" :key="k" :id="k" 
                        :class="v.aur_have_nilai_kritis_lab == 'Y' || v.aur_have_nilai_kritis_radiologi == 'Y' ? 'table-danger' : ''">
                        <td>{{(data.per_page*(data.current_page-1))+k+1}}
                        </td>
                        <template v-if="v.aur_triase_id">
                            <td :class="'triase_'+v.color">
                            <strong class="font-weight-semibold text-white">{{v.triase_type||"-"}}</strong>
                            </td>
                        </template>
                        
                        <template v-else>
                        <td>
                            <span class="font-weight-semibold text-warning-600">Belum Ada</span>
                        </td>
                        </template>

                        <td>
                            <div class="text-success-700 font-weight-semibold">{{v.aur_reg_code||"-"}}</div>
                        </td>
                        
                        
                        
                            
                        <td>
                            <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                            <small :class="v.ap_is_old_pasien == 'Y' ? 'font-weight-semibold text-danger' : 'font-weight-semibold text-primary'" style="font-size: 12px;">
                            {{v.ap_is_old_pasien == 'Y' ? 'PASIEN LAMA' : 'PASIEN BARU'}}</small>
                        </td>
                        
                        <td>
                            <div :class="v.aur_have_nilai_kritis_lab == 'Y' || v.aur_have_nilai_kritis_radiologi == 'Y'?'text-danger':''">{{v.ap_fullname||"-"}}</div>
                            <div class="text-info">{{v.ap_nik||"-"}}</div>
                            <span v-if="v.aur_is_emergency == 'Y'" :class="`badge bg-danger mt-2`">Emergency</span>
                        </td>
                        
                        <td>
                            <div v-if="v.ap_dob" class="font-weight-semibold">
                                {{v.ap_dob | moment("DD MMM YYYY")}}
                            </div>
                            <span v-else> - </span>
                        </td>
                        <td>
                            {{v.ap_usia_with_ket||"-"}}
                            <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                        </td>
                        
                        <td>
                            {{v.ap_gender == 1 ? "Laki-Laki" : v.ap_gender == 2 ? "Perempuan" : "Tidak Diketahui"}}
                        </td>
                        
                        <td>
                            <div class="font-weight-semibold">{{v.aur_reg_date | moment("DD MMMM YYYY, HH:mm") }}</div>
                        </td>
                        
                        <td>
                            <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.statusJaminan,v.aures_jaminan)||"-"}}</div>
                        </td>
                        <td>
                            
                            <div class="badge badge-dark mb-1" v-if="v.aur_is_void == 'Y'">
                                VOID
                            </div>
                            <template v-else>
                                <!-- 2 == dalam pengkajian --> 
                                <template v-if="v.aur_status == 2">
                                    <div class="badge bg-warning mb-1" v-if="v.aur_is_primary_assesment_done != 'Y'">
                                        Kajian Primer
                                    </div>
                                    
                                    <div class="badge bg-warning mb-1" v-if="v.aur_is_secondary_assesment_done != 'Y'">
                                        Kajian Sekunder
                                    </div>

                                    <div class="badge bg-info mb-1" v-if="v.aur_is_lab == 'Y' && v.aur_is_lab_done != 'Y'">
                                        Laboratorium
                                    </div>
                                    <div class="badge bg-info mb-1" v-if="(v.aur_is_radiologi == 'Y' || v.aur_is_intra_penunjang == 'Y') && v.aur_is_radiologi_done != 'Y'">
                                        Radiologi
                                    </div>
                                </template>
                                <span v-else :class="`badge ${v.aus_color}`">{{uppercaseWord(v.aus_name)||'-'}}</span>
                            </template>

                            <div class="mt-1"></div>

                            <span v-if="(v.aur_tindak_lanjut||[]).indexOf('RU') != -1" class="badge bg-success mb-1">Rujuk</span>
                            <span v-else-if="(v.aur_tindak_lanjut||[]).indexOf('RJ') != -1" class="badge bg-primary mb-1">Rawat Jalan</span>
                            <span v-else-if="(v.aur_tindak_lanjut||[]).indexOf('O') != -1" class="badge bg-info mb-1">Observasi</span>
                            <span v-if="(v.aur_tindak_lanjut||[]).indexOf('P') != -1" class="badge bg-success mb-1">PARTUS</span>
                            <span v-if="(v.aur_tindak_lanjut||[]).indexOf('RI') != -1" class="badge bg-warning mb-1">RAWAT INAP</span>

                            <span v-if="(v.aur_tindak_lanjut||[]).indexOf('RJ') != -1 && v.aur_tindak_lanjut_rajal == 'KM'" class="badge bg-primary mb-1">Keluhan Membaik</span>
                            <span v-if="(v.aur_tindak_lanjut||[]).indexOf('RJ') != -1 && v.aur_tindak_lanjut_rajal == 'MU'" class="badge bg-primary mb-1">Meninggal di UGD</span>
                            <span v-if="(v.aur_tindak_lanjut||[]).indexOf('RJ') != -1 && v.aur_tindak_lanjut_rajal == 'DOA'" class="badge bg-primary mb-1">DOA/Meninggal</span>
                            <span v-if="(v.aur_tindak_lanjut||[]).indexOf('RJ') != -1 && v.aur_tindak_lanjut_rajal == 'MR'" class="badge bg-primary mb-1">Menolak Rawat Inap</span>

                            <span v-if="!(v.aur_tindak_lanjut||[]).length" class="badge bg-primary mb-1">Tidak ada tindak lanjut</span>
                            
                            <span v-if="v.aur_have_nilai_kritis_lab == 'Y' || v.aur_have_nilai_kritis_radiologi == 'Y'" class="badge bg-danger mb-1" style="background-color: #640009 !important;">Kritis</span>

                          
                        </td>
                        <td>
                          <a href="javascript:;" v-if="moduleRole('et_perawat')" v-b-tooltip.hover :class="(v.aur_is_have_et_perawat == 'Y'?'btn btn-icon rounded-round btn-sm alpha-success border-success mb-1':'btn btn-icon rounded-round btn-sm alpha-warning border-warning mb-1')
                          " @click="toET(v.aur_id,'PERAWAT')" 
                          :title="(v.aur_is_have_et_perawat == 'Y'?'Edit':'Tambah')+' Edukasi Terintegrasi Perawat'"><i class="icon-pencil4"></i></a>
                          
                          <a href="javascript:;" v-if="moduleRole('et_bidan')" v-b-tooltip.hover :class="(v.aur_is_have_et_bidan == 'Y'?'btn btn-icon rounded-round btn-sm alpha-success border-success mb-1':'btn btn-icon rounded-round btn-sm alpha-warning border-warning mb-1')
                          " @click="toET(v.aur_id,'BIDAN')" :title="(v.aur_is_have_et_bidan == 'Y'?'Edit':'Tambah')+' Edukasi Terintegrasi Bidan'"><i class="icon-pencil4"></i></a>
                          
                          <a href="javascript:;" v-if="moduleRole('et_dokter')" v-b-tooltip.hover :class="(v.aur_is_have_et_dokter == 'Y'?'btn btn-icon rounded-round btn-sm alpha-success border-success mb-1':'btn btn-icon rounded-round btn-sm alpha-warning border-warning mb-1')
                          " @click="toET(v.aur_id,'DOKTER')" :title="(v.aur_is_have_et_dokter == 'Y'?'Edit':'Tambah')+' Edukasi Terintegrasi Dokter'"><i class="icon-pencil4"></i></a>
                          
                          <a href="javascript:;" v-if="moduleRole('et_farmasi')" v-b-tooltip.hover :class="(v.aur_is_have_et_farmasi == 'Y'?'btn btn-icon rounded-round btn-sm alpha-success border-success mb-1':'btn btn-icon rounded-round btn-sm alpha-warning border-warning mb-1')
                          " @click="toET(v.aur_id,'FARMASI')" :title="(v.aur_is_have_et_farmasi == 'Y'?'Edit':'Tambah')+' Edukasi Terintegrasi Farmasi'"><i class="icon-pencil4"></i></a>
                          
                          <a href="javascript:;" v-if="moduleRole('et_nyeri')" v-b-tooltip.hover :class="(v.aur_is_have_et_nyeri == 'Y'?'btn btn-icon rounded-round btn-sm alpha-success border-success mb-1':'btn btn-icon rounded-round btn-sm alpha-warning border-warning mb-1')
                          " @click="toET(v.aur_id,'NYERI')" :title="(v.aur_is_have_et_nyeri == 'Y'?'Edit':'Tambah')+' Edukasi Terintegrasi Nyeri'"><i class="icon-pencil4"></i></a>




                          <a href="javascript:;" v-if="moduleRole('et_harian_perawat')" v-b-tooltip.hover :class="'btn btn-icon rounded-round btn-sm alpha-danger border-danger mb-1'" @click="toET(v.aur_id,'PERAWAT_HARIAN')" 
                          :title="'Tambah Edukasi Harian Perawat'"><i class="icon-pencil4"></i></a>
                          
                          <a href="javascript:;" v-if="moduleRole('et_harian_bidan')" v-b-tooltip.hover :class="(v.aur_is_have_et_harian_bidan == 'Y'?'btn btn-icon rounded-round btn-sm alpha-primary border-primary mb-1':'btn btn-icon rounded-round btn-sm alpha-danger border-danger mb-1')
                          " @click="toET(v.aur_id,'BIDAN_HARIAN')" :title="(v.aur_is_have_et_harian_bidan == 'Y'?'Edit':'Tambah')+' Edukasi Harian Bidan'"><i class="icon-pencil4"></i></a>
                          
                          <a href="javascript:;" v-if="moduleRole('et_harian_dokter')" v-b-tooltip.hover :class="'btn btn-icon rounded-round btn-sm alpha-danger border-danger mb-1'" @click="toET(v.aur_id,'DOKTER_HARIAN')" :title="'Tambah Edukasi Harian Dokter'"><i class="icon-pencil4"></i></a>
                          
                          <a href="javascript:;" v-if="moduleRole('et_harian_farmasi')" v-b-tooltip.hover :class="'btn btn-icon rounded-round btn-sm alpha-danger border-danger mb-1'" @click="toET(v.aur_id,'FARMASI_HARIAN')" :title="'Tambah Edukasi Harian Farmasi'"><i class="icon-pencil4"></i></a>
                          
                          <a href="javascript:;" @click="viewDetail(v)" v-if="moduleRole('lihat_detail')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-info border-info mb-1" title="Lihat Detail Edukasi"><i class="icon-eye"></i></a>
                        </td>
                    </tr>
                </tbody>
                
                <tbody v-if="data.data.length == 0">
                    <tr>
                        <td colspan="99">
                        <div class="text-center">
                            <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                            <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                        </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="!data.data">   
                    <tr>
                        <td colspan="99"><div class="skeletal-comp"></div></td>
                    </tr>
                    <tr>
                        <td colspan="99"><div class="skeletal-comp"></div></td>
                    </tr>
                    <tr>
                        <td colspan="99"><div class="skeletal-comp"></div></td>
                    </tr>
                </tbody>
            </table>

            
            <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                <i class="icon-chevron-left"></i>
                </a>
                <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                <i class="icon-chevron-right"></i>
                </a>
            </div>
        </div>

        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
        />
        </b-card-footer>
      </div>


      <b-modal v-model="openDetailET" :title="'Detail Isi Edukasi'" size="xl" ok-only ok-title="Tutup">
        <ModalDetail />
      </b-modal>

    </div>
    <template v-else>
      <Form :row.sync="row" v-bind="passToSub" />
    </template>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  const moment = require('moment')

  import Form from './Form.vue'
  import ModalDetail from './ModalDetail.vue'
  
  const _ = global._
  import $ from 'jquery'
  import Gen from '@/libs/Gen.js'

  export default {
    extends: GlobalVue,
    components:{
      DateRangePicker,Form,ModalDetail
    },
    data(){
      return {
        idKey:'aur_id',
        datePickerConfig: {
            startDate: new Date(),
            endDate: new Date(),
            autoApply: true,
            ranges: {
                'Hari Ini': [new Date(), new Date()],
                '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
            },
            applyClass: 'btn-sm btn-primary',
            cancelClass: 'btn-sm btn-light',
            locale: {
                applyLabel: 'Terapkan',
                cancelLabel: 'Batal',
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
            }
        },
        dateRange: {
            startDate: new Date(),
            endDate: new Date(),
        },
        pasienLama: 0,
        pasienBaru: 0,
        rowReg: {},
        rowPasien: {},
        rowET: {},
        dataET: [],
        dataETHarian: [],
        openDetailET: false
      }
    },
    methods: {
      viewDetail(row){
        this.loadingOverlay = true
        let data = {
          type : 'view-detail',
          id: row.aur_id
        }
        Gen.apiRest(
            "/do/"+'UGDET',
            {data:data}, 
            "POST"
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
          this.loadingOverlay = false
          this.openDetailET = true
        })
      },
      setSlide(){
          const responsiveTable = document.querySelector('.table-responsive')
          if(responsiveTable){
              const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
              const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
              if(responsiveTableScroll){
                  // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                  Array.from(responsiveTableScrollActions).forEach(action => {
                      action.addEventListener('click', e => {
                          const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                            
                          responsiveTable.scroll({
                              left: scrollAmount,
                              behavior: 'smooth'
                          })
                      })
                  })
              }
          }
      },
      windowTableScroller(e){
          const responsiveTable = document.querySelector('.table-responsive')
          if(responsiveTable){
              const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
              if(responsiveTableScroll){
                  // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                  this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                  const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                  if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                      this.onScrollPoint = true
                      document.documentElement.scroll(0, this.tableScrollPoint)
                      window.addEventListener('wheel', this.tableScrollFunction, false)
                  }
              }
          }
      },
      doResetData(){
          this.doReset()
          this.dateRange =  {
              startDate: new Date(),
              endDate: new Date(),
          }
          this.doConvertDate()
      },
      doRefreshData(){
          this.apiGet()
          window.scrollTo(0,0)
      },
      apiGetResume(){
          let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
          Gen.apiRest(
              "/get/"+this.modulePage+'/resume', 
              {
              params: Object.assign({}, paramsQuery||{})
              }
          ).then(res=>{
              _.forEach(res.data, (v,k)=>{
                  this.$set(this, k, v)
              })
          })
      },
      doConvertDate(){
        this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
        this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      },
      doPageOne(){
        this.filter.page = 1
      },
      doFill(){
        this.doPageOne()
        this.doFilter()
      },
      updateValues(e){
        this.doConvertDate()
        this.doFill()
      },
      doSearch: _.debounce(function(){
        this.doPageOne()
        this.doFilter()
      },500),
      getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
      },
      toET(regId,type){
        this.$router.push({ 
          name: this.modulePage, 
          params: { pageSlug: regId }, 
          query: {typeForm: type }
        })
        .catch(()=>{})
      },
    },
    mounted(){
      this.filter.status_pasien = 'ALL'
      this.filter.status_periksa = 'ALL'
      this.filter.status_jaminan = 'ALL'
      this.filter.status_rujukan = 'ALL'

      this.filter.is_draft = this.tabDraft
      
      this.doConvertDate()      
      this.apiGet()
      
      if(this.isList){
          this.apiGetResume()
      }
          
      setTimeout(()=>{
          this.setSlide()
      },1000)
    },
    filters: {
      date(val) {
        return val ? moment(val).format("D MMM YYYY") : ""
      }
    },
    computed:{
      passToSub(){
        return {
          Config:this.Config,
          mrValidation: this.mrValidation,
          rowPasien: this.rowPasien,
          rowReg: this.rowReg,
          rowET: this.rowET
        }
      },
    },
    watch:{
      $route(){
        this.apiGet()
        if(this.isList){
          this.apiGetResume()
        }
        
        setTimeout(()=>{
          if(this.isList){
            this.setSlide()
          }
        },1000)
      },
      '$route.params.pageSlug':function(){
        this.$set(this,'filter',{})
      }
    }
  }
</script>