<template> 
  <div>
    <b-tabs nav-class="nav-tabs-bottom nav-justified mt-2 mb-0">
      <b-tab title="Edukasi Terintegrasi">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive mb-0">
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th colspan="2">Uraian Penjelasan Edukasi</th>
                    <th>Penerima Penjelasan / Sasaran</th>
                    <th>Tanda Tangan</th>
                  </tr>
                </thead>
                <tbody v-for="(v, k) in isParent.dataET" :key="`et-${k}`">
                  <tr class="table-info">
                    <td colspan="4">
                      <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <div class="font-weight-semibold mr-2">Form Edukasi <span class="text-capitalize" v-if="v.auet_type == 'PERAWAT' || v.auet_type == 'NYERI'">{{ (v.auet_type||'').toLowerCase() }}</span> - {{ v.auet_tanggal_edukasi | moment("DD MMMM YYYY") }} {{v.auet_waktu_edukasi}}</div>
                          <small class="text-secondary">Pelapor: 
                            <span class="text-capitalize" v-if="v.auet_type == 'PERAWAT' || v.auet_type == 'NYERI'">Perawat</span>
                            <span class="text-capitalize" v-else>{{ v.auet_type.toLowerCase() }}</span>
                            
                             - {{ v.auet_updated_date || v.auet_created_name }}</small>
                          <b-badge variant="danger" class="ml-2">{{getConfigDynamic(v.mr_evaluasi_edukasi,v.auet_evaluasi)}}</b-badge>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-top" width="35%">
                      <div class="mb-2">
                        <div class="font-weight-semibold">Materi Edukasi</div>
                        <ul class="pl-3 mb-0">
                          <li v-for="(materi, kMateri) in v.auet_materi_edukasi" :key="`mat-${kMateri}`">{{ materi.label }}</li>
                        </ul>
                      </div>
                      <div class="mb-2">
                        <div class="font-weight-semibold">Tingkat Pemahaman Awal</div>
                        <span>{{ getConfigDynamic(v.mr_pemahaman_awal,v.auet_tingkat_pemahaman_awal)||"-" }}</span>
                      </div>
                      <div class="mb-2" v-if="v.question">
                        <div class="font-weight-semibold">Pertanyaan</div>
                        <span>{{ v.auet_question }}</span>
                      </div>
                    </td>
                    <td class="align-top" width="35%">
                      <div class="mb-2">
                        <div class="font-weight-semibold">Metode Edukasi</div>
                        <span>{{ v.auet_metode_edukasi.map(dtMetode => dtMetode.label).join(", ") }}</span>
                      </div>
                      <div class="mb-2">
                        <div class="font-weight-semibold">Sarana Edukasi</div>
                        <span>{{ v.auet_sarana_edukasi.map(dtMetode => dtMetode.label).join(", ") }}</span>
                      </div>
                      <div class="mb-2">
                        <div class="font-weight-semibold">Evaluasi</div>
                        <span>{{ getConfigDynamic(v.mr_evaluasi_edukasi,v.auet_evaluasi)||"-" }}</span>
                      </div>
                      <div class="mb-2" v-if="v.auet_tanggal_evaluasi && v.auet_waktu_evaluasi">
                        <div class="font-weight-semibold">Tanggal Re-edukasi</div>
                        <span>{{ v.auet_tanggal_evaluasi | moment("DD MMMM YYYY") }}
                          {{v.auet_waktu_evaluasi}}
                        </span>
                      </div>
                    </td>
                    <td class="align-top">
                      <span>{{ v.auet_hubungan_pasien }} - {{ v.auet_hubungan_pasien_nama }}</span>
                    </td>
                    <td class="align-top text-center">
                      <a href="javascript:;" @click="viewTTD(v,'auet_edukator')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-info border-info mb-1 d-block" title="Lihat TTD Edukator"><i class="icon-eye"></i></a>
                      
                      <a href="javascript:;" @click="viewTTD(v,'auet_sasaran')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-success border-success mb-1 d-block" title="Lihat TTD Sasaran"><i class="icon-eye"></i></a>
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="!(isParent.dataET||[]).length">
                  <tr>
                    <td colspan="99">
                      <h5 class="text-center">
                        Belum ada data edukasi terintegrasi
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab title="Edukasi Harian">
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive mb-0">
              <table class="table table-bordered table-sm">
                <thead>
                  <tr>
                    <th colspan="2">Uraian Penjelasan Edukasi</th>
                    <th>Penerima Penjelasan / Sasaran</th>
                    <th>Tanda Tangan</th>
                  </tr>
                </thead>
                <tbody v-for="(v, k) in isParent.dataETHarian" :key="`eh-${k}`">
                  <tr class="table-info">
                    <td colspan="4">
                      <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <div class="font-weight-semibold mr-2">{{ v.auieth_tanggal_edukasi | moment("DD MMMM YYYY") }} {{v.auieth_waktu_edukasi}}</div>
                          <small class="text-secondary">
                            Pelapor: <span class="text-capitalize">{{ getType(v.auieth_type) }}</span> - {{ v.aueith_updated_name||v.auieth_created_name }}</small>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-top" colspan="2">
                      <p v-html="v.auieth_uraian_penjelasan"></p>
                    </td>
                    <td class="align-top">
                      <span>{{ v.auieth_hubungan_pasien }} - {{ v.auieth_hubungan_pasien_nama }}</span>
                    </td>
                    <td class="align-top text-center">
                      <a href="javascript:;" @click="viewTTD(v,'auieth_edukator')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-info border-info mb-1 d-block" title="Lihat TTD Edukator"><i class="icon-eye"></i></a>
                      
                      <a href="javascript:;" @click="viewTTD(v,'auieth_sasaran')" v-b-tooltip.hover class="btn btn-icon rounded-round btn-sm alpha-success border-success mb-1 d-block" title="Lihat TTD Sasaran"><i class="icon-eye"></i></a>
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="!(isParent.dataETHarian||[]).length">
                  <tr>
                    <td colspan="99">
                      <h5 class="text-center">
                        Belum ada data edukasi harian
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <b-modal v-model="openTTD" :title="'TTD Digital'" size="sm" ok-only ok-title="Tutup">
      <div class="row">
        <div class="col-md-12 text-center">
          <img :src="ttdDigital" alt="">
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  export default{
    extends: GlobalVue,
    computed: {
      isParent(){
        return this.$parent.$parent.$parent
      },
    },
    data(){
      return {
        ttdDigital: '',
        openTTD: false,
      }
    },
    methods: {
      getType(type){
        if(type == 'DOKTER' || type == 'DOKTER_HARIAN'){
          type = "Dokter"
        }else if(type == 'BIDAN' || type == 'BIDAN_HARIAN'){
          type = "Bidan"
        }else if(type == 'NYERI' || type == 'NYERI_HARIAN'){
          type = "Nyeri"
        }else if(type == 'FARMASI' || type == 'FARMASI_HARIAN'){
          type = "Farmasi"
        }else{
          type = "Perawat"
        }
        return type
      },
      getConfigDynamic(master,value,field='label'){
          let text = ''
          if(value){
              let index = (master||[]).findIndex(x => x.value == value)
              if(index !== -1){
                  text = master[index][field]
              }
          }
          return text
      },
      viewTTD(v,field){
        this.ttdDigital = v[field]
        this.openTTD = true
      }
    },
  }
</script>