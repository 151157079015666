<template>  
  <div class="content-wrapper overflow-visible">
    <div class="page-header page-header-sticky page-header-sticky-open">
        <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">
            <div class="page-header-info flex-1 py-3 text-uppercase">
                <div class="d-flex justify-content-between">
                    <div>
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                <span>{{rowReg.ap_fullname||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No Rekam Medis">{{rowReg.ap_code||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No KTP">{{rowReg.ap_nik||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                            </h5>
                        </div>
                        <div class="phi-sub-info d-flex">
                            <div class="phi-info d-flex flex-column">
                                <small>Tanggal Lahir</small>
                                <strong
                                    class="font-weight-semibold">{{rowReg.ap_dob | moment("DD MMM YYYY")}}</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Usia</small>
                                <strong
                                    class="font-weight-semibold">{{rowReg.ap_usia_with_ket || "-"}}<br />({{rowReg.ap_gol_usia||"-"}})</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Jenis Kelamin</small>
                                <strong class="font-weight-semibold">{{rowReg.cg_label}}</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Opsi Pembayaran</small>
                                <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Agama</small>
                                <strong class="font-weight-semibold">{{rowReg.ap_agama_text || "-"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <a href="javascript:;" v-if="row.kajianPerawat" @click="toDetailKajianPerawatUGD()" data-toggle="modal" data-target="#modalKajianAwalPerawat" class="btn btn-outline-light">Lihat Kajian Perawat</a>
                        <a href="javascript:;" v-if="row.have_rajal" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-1" @click="doOpenRiwayatRMV2(row.kajianRajal)">Lihat Riwayat Rekam Medis Rajal</a>
                        <a href="javascript:;" @click="doOpenRiwayatRMUGD(row)" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis UGD</a>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
        </a>
    </div>

    <div class="content">
      <template v-if="typeForm == 'PERAWAT' || typeForm == 'DOKTER' || typeForm == 'BIDAN' || typeForm == 'FARMASI' || typeForm == 'NYERI'">
        <FormET />
      </template>
      <template v-else>
        <FormETH />
      </template>
    </div>

  
    <b-modal v-model="openDetailKajian" :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
        <DetailKajianAwal2 v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
        <DetailKajianLanjutan2 v-bind="passToSubLanjutan" v-else />
    </b-modal>
    
        
    <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
        <RiwayatRM2 v-bind="passToSubRM" />
    </b-modal>        
    
    <b-modal v-model="openDetailKajianPerawatUGD" title="Kajian Sekunder" size="xl" hide-footer>
        <KajianPerawatUGD v-bind="passToSubPerawatUGD" /> 
    </b-modal>
    
    <b-modal v-model="openRiwayatRMUGD" title="Detail Riwayat Rekam Medis" size="xl" hide-footer>
        <RiwayatRMUGD2 v-bind="passToSubRMUGD" />
    </b-modal>'

</div>
</template>


<script>
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'

  import DetailKajianAwal2 from '@/components/RiwayatPOLI/DetailKajianAwal2.vue'
  import DetailKajianLanjutan2 from '@/components/RiwayatPOLI/DetailKajianLanjutan2.vue'
  import RiwayatRM2 from '@/components/RiwayatPOLI/RiwayatRM2.vue'

  import RiwayatRMUGD2 from '@/components/RiwayatUGD/RiwayatRMUGD2.vue'
  import KajianPerawatUGD from '@/components/RiwayatUGD/KajianPerawatUGD2.vue'

  import FormET from './FormET.vue'
  import FormETH from './FormETH.vue'
  
  
  export default {
    props: {
        row: Object,
        rowET: Object,
        mrValidation: Object,
        Config: Object,
        rowReg: Object,
        mKesadaran: Array,
        informConcent: Array
    },
    data() {
      return {
        openDetailKajian: false,
        openDetailKajianPerawat: false,
        openRwiayatRM: false,
        dataRiwayatRMDokter: {},
        dataRiwayatRMDokterTerapi: {},
        dataRiwayatRMDokterGizi: {},
        dataRiwayatRMFisio: {},
        dataRiwayatRMRadiologi: {},
        dataRiwyatRMPerawat: {},
        dataRiwayatRMLab: {},
        dataRiwyatRMPerawatIS: '',
        historyKajianPerawat: [],
        historyKajianDokter: [],
        historyKajianDokterTerapi: [],
        historyKajianDokterGizi: [],
        historyKajianFisio: [],
        historyKajianRadiologi: [],
        historyKajianLab: [],
        dataRiwayatRMFollowUp: {},
        openDetailKajianPerawatUGD: false,
        openRiwayatRMUGD: false,
        dataKajianPerawat: {},
        dataKajianDokter: {},
        historiKajianPerawat: [],
        historiKajianDokter: [],
        masterKajianPerawat: {},
        masterKajianDokter: {},
        masterTriase: {},
        dataKajianTriase: {},
        historiKajianRadiologi: [],
        historiKajianLab: [],
        historiKajianTriase: [],
        dataKajianRadiologi: {},
        dataKajianLab: {},
        dataKajianFarmasi: [],
        dataUGDReservasi: {},
        activeTabInformasi: 1,    
        resepDokter: [],     
        openPengantar: false,
        pengantarRanap: {},

        dataKajian: [],
        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,

        patientData: {},
        loading: {
            patientInfo: false,
        }
      }
    },
    computed: {
      typeForm(){ return this.$route.query.typeForm },
      
      passToSub() {
          return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          mPekerjaan: this.mPekerjaan,
          mAlatBantu: this.mAlatBantu,
          mSkrininGizi: this.mSkrininGizi,
          mSkrininGiziV2: this.mSkrininGiziV2,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSLKI: this.mSLKI,
          mSIKI: this.mSIKI,
          mPoli: this.mPoli,
          mDokter: this.mDokter,
          mKesadaran: this.mKesadaran,
          resepDokter: this.resepDokter,
          historyKajianPerawat: this.historyKajianPerawat
          })
      },
      passToSubLanjutan() {
          return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSIKI: this.mSIKI,
          mKesadaran: this.mKesadaran,
          resepDokter: this.resepDokter,
          historyKajianPerawat: this.historyKajianPerawat
          })
      },
      passToSubRM() {
          return _.assign({
          dataRiwayatRMDokter: this.dataRiwayatRMDokter,
          dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
          dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
          dataRiwayatRMFisio: this.dataRiwayatRMFisio,
          dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
          dataRiwayatRMLab: this.dataRiwayatRMLab,
          dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,

          historyKajianPerawat: this.historyKajianPerawat,
          historyKajianDokter: this.historyKajianDokter,
          historyKajianDokterTerapi: this.historyKajianDokterTerapi,
          historyKajianDokterGizi: this.historyKajianDokterGizi,
          historyKajianFisio: this.historyKajianFisio,
          historyKajianRadiologi: this.historyKajianRadiologi,
          historyKajianLab: this.historyKajianLab,

          row: this.row,
          rowReg: this.rowReg,
          dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
          })
      },

      
      passToSubPerawatUGD(){
          return _.assign({
              isAdd:this.isAdd,
              mrValidation:this.mrValidation,
              Config:this.Config,
              row: this.row,
              rowReg: this.rowReg,
              dataKajianPerawat: this.dataKajianPerawat,
              historiKajianPerawat: this.historiKajianPerawat,
              masterKajianPerawat: this.masterKajianPerawat,
          })
      },

      passToSubRMUGD(){
          return _.assign({
              isAdd:this.isAdd,
              mrValidation:this.mrValidation,
              Config:this.Config,
              row: this.row,
              rowReg: this.rowReg,
              dataKajianDokter: this.dataKajianDokter,
              dataKajianTriase: this.dataKajianTriase,
              historiKajianDokter: this.historiKajianDokter,
              masterKajianDokter: this.masterKajianDokter,
              masterTriase: this.masterTriase,
              historiKajianRadiologi: this.historiKajianRadiologi,
              historiKajianLab: this.historiKajianLab,
              historiKajianTriase: this.historiKajianTriase,
              dataKajianRadiologi: this.dataKajianRadiologi,
              dataKajianLab: this.dataKajianLab,
              dataKajianFarmasi: this.dataKajianFarmasi,
              dataUGDReservasi: this.dataUGDReservasi
          })
      },
    },
    components: {
      DetailKajianAwal2,
      DetailKajianLanjutan2,
      RiwayatRM2,
      KajianPerawatUGD,
      RiwayatRMUGD2,
      FormET,
      FormETH
    },
    methods: {
      back(){
        this.$router.push({name : 'UGDTindakLanjut'}).catch({})
      },
      initSticky() {
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if (PageHeaderSticky) {
          const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
          if ($(".page-header-sticky").length && $(".page-header-sticky-toggle").length) {
            PageHeaderToggle.addEventListener('click', function (e) {
              // e.preventDefault()
              PageHeaderSticky.classList.toggle('page-header-sticky-open')
              if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                PageHeaderToggle.querySelector('span').innerText =
                    'Sembunyikan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
              } else {
                PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
              }
            })
          }
        }
      },
      getConfigDynamic(master,value){
          let text = ''
          if(value){
              let index = (master||[]).findIndex(x => x.value == value)
              if(index !== -1){
                  text = master[index]['text']
              }
          }
          return text
      },
      doOpenRiwayatRMUGD(v){
          this.$parent.loadingOverlay = true
          
          if(v.kajianDokter){
              Gen.apiRest(
                  "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.openRiwayatRMUGD = true    
                  this.dataKajianDokter = res.data
                  this.$parent.loadingOverlay = false
              })

              Gen.apiRest(
                  "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aur_id+'&master=1', 
              ).then(res=>{
                  this.masterKajianDokter = res.data
              })     
          }
          

          if(v.triase_id){                
              Gen.apiRest(
                  "/get/"+'UGDTriase'+'/'+v.triase_id+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.dataKajianTriase = res.data
              })
          }
                    
          Gen.apiRest(
              "/get/"+'UGDTriase'+'/'+'master', 
          ).then(res=>{
              this.masterTriase = res.data
          })

          
          if(v.kajianLab){
              Gen.apiRest(
                  "/get/"+'UGDFormLab'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.dataKajianLab = res.data
              })
          }
          
          if(v.kajianRadiologi){
              Gen.apiRest(
                  "/get/"+'UGDFormRadiologi'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.dataKajianRadiologi = res.data
              })
          }

          Gen.apiRest(
              "/do/" + 'UGDAssesment', {
                  data: {
                  type: 'get-obat-farmasi',
                  regId: v.aur_id
                  }
              }
          ).then(res => {
              let resp = res.data
              this.dataKajianFarmasi = resp.data
              this.dataUGDReservasi = resp.dataUGDReservasi
              this.$parent.loadingOverlay = false
          })
          
          
          this.openRiwayatRMUGD = true
      },
      toDetailKajianPerawatUGD(){
          this.$parent.loadingOverlay = true
          Gen.apiRest(
              "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+this.row.kajianPerawat+'?regId='+this.row.aur_id, 
          ).then(res=>{
              this.openDetailKajianPerawatUGD = true    
              this.dataKajianPerawat = res.data
              this.$parent.loadingOverlay = false
          })
          Gen.apiRest(
              "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+this.row.kajianPerawat+'?regId='+this.row.aur_id+'&master=1', 
          ).then(res=>{
              this.masterKajianPerawat = res.data
          })
      },
      doOpenRiwayatRMV2(v) {
          this.$parent.loadingOverlay = true
          if (v.kajianDokter) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokter + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMDokter = res.data
              this.dataRiwayatRMDokter.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMDokter = {}
          }

          if (v.kajianDokterTerapi) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokterTerapi + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMDokterTerapi = res.data
              this.dataRiwayatRMDokterTerapi.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMDokterTerapi = {}
          }

          if (v.kajianGizi) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianGizi + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMDokterGizi = res.data
              this.dataRiwayatRMDokterGizi.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMDokterGizi = {}
          }

          if (v.kajianFisio) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianFisio + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMFisio = res.data
              this.dataRiwayatRMFisio.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMFisio = {}
          }

          if (v.kajianRadiologi) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianRadiologi + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMRadiologi = res.data
              this.dataRiwayatRMRadiologi.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMRadiologi = {}
          }

          if (v.kajianLab) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianLab + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMLab = res.data
              this.dataRiwayatRMLab.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMLab = {}
          }

          Gen.apiRest(
          "/do/" + 'RekamMedis', {
              data: {
              type: 'get-obat-farmasi',
              regId: v.ar_id
              }
          }
          ).then(res => {
          let resp = res.data
          this.dataRiwayatRMFarmasi = resp.data
          this.$parent.loadingOverlay = false
          })

          Gen.apiRest(
          "/do/" + 'RekamMedis', {
              data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.ar_id
              }
          }
          ).then(res => {
          let resp = res.data
          // non ppa
          this.historyKajianPerawat = resp.historyKajianPerawat
          this.historyKajianDokter = resp.historyKajianDokter
          this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
          
          this.$parent.loadingOverlay = false
          })

          Gen.apiRest(
          "/do/" + 'RekamMedis', {
              data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.ar_id
              }
          }
          ).then(res => {
          let resp = res.data
          this.$parent.loadingOverlay = false
          
          this.historyKajianDokterGizi = resp.historyKajianDokterGizi
          this.historyKajianFisio = resp.historyKajianFisio
          this.historyKajianRadiologi = resp.historyKajianRadiologi
          this.historyKajianLab = resp.historyKajianLab
          })


          Gen.apiRest(
          "/get/" + 'FollowUp' + '/' + this.row.ap_id + '?regId=' + v.ar_id,
          ).then(res => {
          this.$parent.loadingOverlay = false
          this.dataRiwayatRMFollowUp = res.data
          this.dataRiwayatRMFollowUp.Config = this.Config
          this.openRwiayatRM = true
          })


          setTimeout(() => {
          this.$parent.loadingOverlay = false
          this.openRwiayatRM = true
          }, 2000)
      },
      toValidate(val) {
          return {
              ...val
          }
      },
      autoSaveET: _.debounce(function (data) {
        data.type = 'auto-save-et'
        Gen.apiRest(
            "/do/"+'UGDET',
            {data:data}, 
            "POST"
        )
      },1000),
      
      autoSaveETHarian: _.debounce(function (data) {
        data.type = 'auto-save-et-harian'
        Gen.apiRest(
            "/do/"+'UGDET',
            {data:data}, 
            "POST"
        )
      },1000),
    },
    mounted() {
      setTimeout(() => {
          this.initSticky()
      }, 1000)
    },
        
    watch:{
      rowET: {
        handler(v) {
          if(this.typeForm == 'PERAWAT' || this.typeForm == 'DOKTER' || this.typeForm == 'BIDAN' || this.typeForm == 'FARMASI' || this.typeForm == 'NYERI'){
            this.autoSaveET(v)          
          }else{
            this.autoSaveETHarian(v)          
          }
        },
        deep: true
      },
    }
  }

</script>